import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import classes from "../index.module.css";
import "../../Assets/css/nonModule.css";
import { Navigate } from "react-router-dom";
import SideNavBar from "../Sidebar";
import Header from "../Header";
// use redux provider to get the state
import { useSelector } from "react-redux";

const DefaultLayout = () => {
  const togglesidebar = useSelector((state) => state.userLogin.togglesidebar);
  const currentUser = JSON.parse(sessionStorage.getItem("userInfo"));
  // const currentUser = JSON.parse(sessionStorage.getItem("userInfo"));
  // if (!currentUser || !currentUser.token) {
  //   return <Navigate to="/login" />;
  // }

  useEffect(() => {
    // const currentUser = JSON.parse(sessionStorage.getItem("userInfo"));
    if (!currentUser || !currentUser.token) {
      return <Navigate to="/login" />;
    }
  }, [currentUser])

  useEffect(() => {
    const timer = setInterval(() => {
      // console.log("It is working");
      // checkJwt(dispatch, currentUser._id);
    }, 10000); // 30 minutes in milliseconds

    return () => clearInterval(timer);
  }, [currentUser]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Header />
      </div>
      <div className={classes.mainLayout}>
        <div className={togglesidebar ? classes.sidebar : classes.smallSidebar}>
          <SideNavBar />
        </div>
        <div className={classes.content}>
          <div className={classes.scrollableOutlet}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;

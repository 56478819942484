import React, { useEffect, useState } from "react";
import { Card, Typography, Chip, Button, Menu, MenuItem } from "@mui/material";
import { BsThreeDotsVertical } from "react-icons/bs";
import classes from "./Subscription.module.css";
import { generateColor } from "../../../Utilities/commonfunctions";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  deleteSubscription,
  activateSubscription,
  deactivateSubscription,
} from "../../../Redux/actions/subscriptionAction";
import moment from "moment";
import CommonConformationmodal from "../../../Components/CommonConformationmodal";

const SubscriptionListItem = ({ subscription, deleteSubs }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { _id } = subscription || {};
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(subscription.is_active);
  const [type, setType] = useState();
  const [isConfermationModal, setIsConfermationModal] = useState(false);

  const handleActivate = () => {
    dispatch(activateSubscription(_id));
    setIsActive(true);
    modalClose();
  };

  const handleClickDelete = () => {
    handleClose();
    setType("delete");
    setIsConfermationModal(true);
  };

  const handleDelete = () => {
    dispatch(deleteSubscription(_id));
    console.log("It trifgers");
    deleteSubs(true);
    modalClose();
  };

  const handleDeactivate = () => {
    dispatch(deactivateSubscription(_id));
    setIsActive(false);
    modalClose();
  };
  useEffect(() => {
    console.log(subscription);
  }, [subscription]);

  const handleMenuclick = (event, item) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const modalClose = () => {
    setIsConfermationModal(false);
    handleClose();
  };

  const handleTriggerStatusFunction = () => {
    handleClose();
    setType("status");
    setIsConfermationModal(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="col-xl-4 mb-4" key={subscription._id}>
      <Card className={classes.MainCardDiv}>
        <div
          style={{
            borderBottom: `4px solid ${generateColor(
              subscription.packageName
            )}`,
            background: `${generateColor(subscription.packageName)}0d`,
            padding: "20px",
          }}
        >
          <div
            className={classes.displayFlex}
            style={{ justifyContent: "space-between" }}
          >
            <Typography className={classes.PackageNameMain}>
              {subscription.packageName}
            </Typography>
            <div className={classes.displayFlex}>
              <Chip
                label={isActive ? "Active" : "Inactive"}
                size="small"
                style={{
                  backgroundColor: isActive ? "#E9F7EC" : "#FCE3E5",
                  color: isActive ? "#317D41" : "#BA1221",
                  padding: "10px",
                }}
              />
              <Button
                variant="text"
                size="small"
                style={{ minWidth: 0 }}
                onClick={(e) => handleMenuclick(e)}
              >
                <BsThreeDotsVertical />
              </Button>
              <Menu
                className={classes.MenuContainer}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {!isActive ? (
                  <MenuItem
                    key={1}
                    onClick={handleTriggerStatusFunction}
                    className={`${classes.packageSmallHEading} ${classes.nrmlfontweight} ${classes.MenuItems}`}
                  >
                    <span>Activate</span>
                  </MenuItem>
                ) : (
                  <MenuItem
                    key={2}
                    onClick={handleTriggerStatusFunction}
                    className={`${classes.packageSmallHEading} ${classes.nrmlfontweight} ${classes.MenuItems}`}
                  >
                    <span>Deactivate</span>
                  </MenuItem>
                )}
                <MenuItem
                  key={3}
                  onClick={() =>
                    navigate("/subscription-management/edit", {
                      state: { id: _id },
                    })
                  }
                  className={`${classes.packageSmallHEading} ${classes.nrmlfontweight} ${classes.MenuItems}`}
                >
                  <span>Edit</span>
                </MenuItem>
                <MenuItem
                  key={4}
                  onClick={() => handleClickDelete()}
                  className={`${classes.packageSmallHEading} ${classes.nrmlfontweight} ${classes.MenuItems}`}
                >
                  <span>Delete</span>
                </MenuItem>
              </Menu>
            </div>
          </div>
          <div
            className={`mt-4 ${classes.displayFlex}`}
            style={{ justifyContent: "space-between" }}
          >
            <div>
              <Typography className={classes.packageSmallHEading}>
                Plan Created On:
              </Typography>
              <Typography
                className={`${classes.packageSmallHEading} ${classes.nrmlfontweight}`}
                style={{ fontWeight: 400 }}
              >
                {moment(subscription.createdAt).format("DD/MM/YYYY")}
              </Typography>
            </div>
            <div>
              <Typography className={classes.packageSmallHEading}>
                Last Edited On:
              </Typography>
              <Typography
                className={`${classes.packageSmallHEading} ${classes.nrmlfontweight}`}
                style={{ fontWeight: 400 }}
              >
                {moment(subscription.updatedAt).format("DD/MM/YYYY")}
              </Typography>
            </div>
          </div>
        </div>
        <div style={{ padding: "20px" }}>
          <div
            className={classes.displayFlex}
            style={{ justifyContent: "space-between" }}
          >
            <Typography className={classes.packageSmallHEading}>
              Duration:
            </Typography>
            <Typography className={classes.packageSmallHEading}>
              Exc. of GST
            </Typography>
          </div>
          <div className="mt-2 mb-3">
            {subscription.durations.map((item, index) => {
              return (
                <div key={index}>
                  <Typography className={`${classes.packageSmallHEading} mb-1`}>
                    <b>₹ {item.price} </b>
                    <span className={classes.login_form_lable}>
                      /{item.duration.replaceAll("_", " ")}
                    </span>
                  </Typography>
                </div>
              );
            })}
          </div>
          <hr />
          <div
            className={classes.displayFlex}
            style={{ justifyContent: "space-between" }}
          >
            <Typography
              className={classes.packageSmallHEading}
              style={{ width: "77%" }}
            >
              No. of Team members that can be adds:
            </Typography>
            <Typography className={classes.login_form_lable}>
              Upto {subscription.noOfTeamMembers}
            </Typography>
          </div>
          <hr />
          <div>
            <Typography className={classes.packageSmallHEading}>
              Features
            </Typography>
            {subscription.features && subscription.features.length > 0 ? (
              <div className="border-0">
                <ul className="list-group border-0 mt-2">
                  {subscription.features.map((item, index) => {
                    return (
                      <li className="list-group-item border-0 p-1" key={index}>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="24"
                            height="24"
                            rx="12"
                            fill={`${generateColor(
                              subscription.packageName
                            )}0d`}
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M17.8162 8.20701C18.0701 8.47369 18.0597 8.89568 17.793 9.14954L10.0893 16.4829C9.95773 16.6081 9.78078 16.6742 9.59933 16.666C9.41788 16.6577 9.24766 16.5758 9.12803 16.4391L6.16506 13.0545C5.92254 12.7775 5.95052 12.3563 6.22755 12.1138C6.50459 11.8713 6.92577 11.8992 7.16829 12.1763L9.67342 15.0379L16.8737 8.1838C17.1404 7.92994 17.5624 7.94033 17.8162 8.20701Z"
                            fill={generateColor(subscription.packageName)}
                          />
                        </svg>
                        <span className={classes.login_form_lable}>
                          {item.feature}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </Card>
      {isConfermationModal && (
        <CommonConformationmodal
          type={type}
          callback={
            type === "delete"
              ? handleDelete
              : !isActive
              ? handleActivate
              : handleDeactivate
          }
          modalClose={modalClose}
        />
      )}
    </div>
  );
};

export default SubscriptionListItem;

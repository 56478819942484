import {
  CUSTOMER_REQUESTS,
  CUSTOMER_LIST_SUCCESS,
  CUSTOMER_LIST_FAIL,
  CUSTOMER_SELECTED,
  CUSTOMER_UPDATED,
  SUBSCRIPTION_LIST,
  TEAMS_LIST,
  TIMELINE_LIST,
  AUDIT_TRIAL_LIST,
  CUSTOMER_TEAMS_FAIL,
  CUSTOMER_LIST_PAGINATION,
  CUSTOMER_PAGINATION_REQUESTS,
  CUSTOMER_PAGINATION_FAIL,
  CUSTOMER_LEADS_LIST_SUCCESS,
  REFERAL_REQUEST,
  REFERAL_REQUEST_SUCCESS,
  REFERAL_REQUEST_FAIL,
  INVOICE_REQUEST,
  INVOICE_SUCCESS,
  INVOICE_FAIL,
  INVOICE_ONE_SUCCESS,
  INVOICE_ONE_FAIL,
  COMPANY_LIST_REQUESTS,
  COMPANY_LIST_FAIL,
  COMPANY_LIST_SUCCESS,
  ONE_TEAM_DETAILS_REQUEST,
  ONE_TEAM_DETAILS_SUCCESS,
  ONE_TEAM_DETAILS_FAIL,
  DOWNLOAD_CUSTOMERS_LIST_REQUEST,
  DOWNLOAD_CUSTOMERS_LIST_SUCCESS,
  DOWNLOAD_CUSTOMERS_LIST_FAIL,
} from "../constants/customerConstants";

import { authApi, getAuthorizationHeader } from "../../api/api";

export const fetchCustomers =
  (page, limit, search, array, sort, startDate, endDate) => async (dispatch) => {
    try {
      dispatch({
        type: CUSTOMER_REQUESTS,
      });

      const { data } = await authApi.post(
        `/customers/list?page=${page}&limit=${limit}&search=${search}&sort=${sort}&start_date=${startDate}&end_date=${endDate}`,
        {
          filterArray: array,
        },
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );

      dispatch({
        type: CUSTOMER_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CUSTOMER_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const fetchLeads = (page, limit, search, sort, filter) => async (dispatch) => {
  try {
    dispatch({
      type: CUSTOMER_REQUESTS,
    });

    const { data } = await authApi.put(
      `/customers/leads?page=${page}&limit=${limit}&search=${search}&sort=${sort}`, filter,
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );

    dispatch({
      type: CUSTOMER_LEADS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CUSTOMER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const activeCustomer = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CUSTOMER_REQUESTS,
    });

    const { data } = await authApi.put(`/customers/active/${id}`);
    console.log(data);
    dispatch(fetchCustomer(id));
  } catch (error) {
    dispatch({
      type: CUSTOMER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const inActiveCustomer = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CUSTOMER_REQUESTS,
    });

    const { data } = await authApi.put(`/customers/inactive/${id}`);
    console.log(data);
    dispatch(fetchCustomer(id));
  } catch (error) {
    dispatch({
      type: CUSTOMER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const fetchCustomer = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CUSTOMER_REQUESTS,
    });

    const { data } = await authApi.get(`/customers/${id}`, {
      headers: { Authorization: getAuthorizationHeader() },
    });

    dispatch({
      type: CUSTOMER_SELECTED,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CUSTOMER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const fetchSubscription = (id, sort, filterArray) => async (dispatch) => {
  try {
    dispatch({
      type: CUSTOMER_REQUESTS,
    });

    const { data } = await authApi.post(
      `/customers/${id}/subscriptions?sort=${sort}`, filterArray,
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );

    dispatch({
      type: SUBSCRIPTION_LIST,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CUSTOMER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const fetchAuditTrial = (id, sort, startDate, endDate) => async (dispatch) => {
  try {
    dispatch({
      type: CUSTOMER_REQUESTS,
    });

    const { data } = await authApi.get(
      `/customers/${id}/audit-trail?sort=${sort}&startDate=${startDate}&endDate=${endDate}`,
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );

    dispatch({
      type: AUDIT_TRIAL_LIST,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CUSTOMER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const fetchTimeLine = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CUSTOMER_REQUESTS,
    });

    const { data } = await authApi.get(`/customers/${id}/timeline`, {
      headers: { Authorization: getAuthorizationHeader() },
    });

    dispatch({
      type: TIMELINE_LIST,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CUSTOMER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const fetchTeams = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CUSTOMER_REQUESTS,
    });

    const { data } = await authApi.get(`/customers/${id}/teams`, {
      headers: { Authorization: getAuthorizationHeader() },
    });

    dispatch({
      type: TEAMS_LIST,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CUSTOMER_TEAMS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createCustomer = (customer) => async (dispatch) => {
  try {
    dispatch({
      type: CUSTOMER_REQUESTS,
    });

    const { data } = await authApi.post(`/customers`, customer);

    dispatch({
      type: CUSTOMER_LIST_PAGINATION,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CUSTOMER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const editCustomer = (id, customer) => async (dispatch) => {
  try {
    dispatch({
      type: CUSTOMER_REQUESTS,
    });

    const { data } = await authApi.put(`/customers/${id}`, customer, {
      headers: { Authorization: getAuthorizationHeader() },
    });

    dispatch({
      type: CUSTOMER_UPDATED,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CUSTOMER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const editCompany = (id, customer, customerID) => async (dispatch) => {
  try {
    dispatch({
      type: CUSTOMER_REQUESTS,
    });

    await authApi.put(`/customers/${id}/company`, customer);

    const { data } = await authApi.get(`/customers/${customerID}`);

    dispatch({
      type: CUSTOMER_SELECTED,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CUSTOMER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const paginationEdit = (rowCount, page) => async (dispatch) => {
  try {
    dispatch({
      type: CUSTOMER_PAGINATION_REQUESTS,
    });

    const data = {
      pagination: {
        rowCount: rowCount,
        currentPage: page,
      },
    };

    dispatch({
      type: CUSTOMER_LIST_PAGINATION,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CUSTOMER_PAGINATION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getRefferalDetails =
  (limit, page, array, sort) => async (dispatch) => {
    try {
      dispatch({
        type: REFERAL_REQUEST,
      });

      console.log(array);

      const { data } = await authApi.get(
        `/referrals?page=${page}&limit=${limit}&active=${array.active && array.active.length > 0 ? array.active[0] : null
        }&sort=${sort}`,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );

      dispatch({
        type: REFERAL_REQUEST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: REFERAL_REQUEST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getInvoices =
  (page, limit, search, array, sort) => async (dispatch) => {
    try {
      dispatch({
        type: INVOICE_REQUEST,
      });

      const { data } = await authApi.post(
        `/invoices?page=${page}&limit=${limit}&search=${search}&sort=${sort}`,
        {
          filterArray: array,
        },
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );

      dispatch({
        type: INVOICE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: INVOICE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getOneInvoice = (name) => async (dispatch) => {
  try {
    dispatch({
      type: INVOICE_REQUEST,
    });
    const { data } = await authApi.get(`/invoices/${name}`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    console.log(data);

    dispatch({
      type: INVOICE_ONE_SUCCESS,
      payload: data[0],
    });
  } catch (error) {
    dispatch({
      type: INVOICE_ONE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getCompaniesList = () => async (dispatch) => {
  try {
    dispatch({
      type: COMPANY_LIST_REQUESTS,
    });

    const { data } = await authApi.get(`/company/list`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    // console.log(data)
    dispatch({
      type: COMPANY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COMPANY_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getOneTeamDetails = (id, sort, active) => async (dispatch) => {
  try {
    dispatch({
      type: ONE_TEAM_DETAILS_REQUEST,
    });
    const { data } = await authApi.get(`/teams/customer/${id}?sort=${sort}&active=${active}`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    console.log(data);
    dispatch({
      type: ONE_TEAM_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ONE_TEAM_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const downloadCustomerList =
  (startDate, endDate) => async (dispatch) => {
    try {
      dispatch({
        type: DOWNLOAD_CUSTOMERS_LIST_REQUEST,
      });
      const { data } = await authApi.get(
        `customers/downloadlist?start_date=${startDate}&end_date=${endDate}`,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );
      // console.log(data);
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      console.log(blob);

      // Create a URL for the blob
      const backendUrl = `${process.env.REACT_APP_API_URL}/customers/downloadlist?start_date=${startDate}&end_date=${endDate}`;

      // Create a link element
      const a = document.createElement("a");
      a.href = backendUrl;
      a.download = "customersReport.xlsx"; // Optional: Set the desired file name
      document.body.appendChild(a);

      // Trigger a click event on the link to initiate the download
      a.click();

      dispatch({
        type: DOWNLOAD_CUSTOMERS_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DOWNLOAD_CUSTOMERS_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const downloadInvoiceList = (startDate, endDate) => async (dispatch) => {
  try {
    dispatch({
      type: DOWNLOAD_CUSTOMERS_LIST_REQUEST,
    });
    const { data } = await authApi.get(
      `invoices/download-invoice-list?start_date=${startDate}&end_date=${endDate}`,
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );
    // console.log(data);
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    console.log(blob);

    // Create a URL for the blob
    const backendUrl = `${process.env.REACT_APP_API_URL}/invoices/download-invoice-list?start_date=${startDate}&end_date=${endDate}`;

    // Create a link element
    const a = document.createElement("a");
    a.href = backendUrl;
    a.download = "customersReport.xlsx"; // Optional: Set the desired file name
    document.body.appendChild(a);

    // Trigger a click event on the link to initiate the download
    a.click();

    dispatch({
      type: DOWNLOAD_CUSTOMERS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DOWNLOAD_CUSTOMERS_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const sendInvoiceToUser = (invoice, setModalToggle) => async (dispatch) => {
  try {

    const { data } = await authApi.post(
      `/invoices/send-invoice-to-user/${invoice}`,
      {},
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );
    console.log(data);
    setModalToggle(true);
    console.log("modfal Triggered");
  } catch (error) {
    dispatch({
      type: DOWNLOAD_CUSTOMERS_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


export const activeEmployee = (team_id, sort, search, id) => async (dispatch) => {
  try {
    dispatch({
      type: CUSTOMER_REQUESTS,
    });

    const { data } = await authApi.put(`/teams/teamMember/${id}/active`, {}, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    console.log(data);
    dispatch(getOneTeamDetails(team_id, sort, search));
  } catch (error) {
    dispatch({
      type: CUSTOMER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const inactiveEmployee = (team_id, sort, search, id) => async (dispatch) => {
  try {
    dispatch({
      type: CUSTOMER_REQUESTS,
    });

    const { data } = await authApi.put(`/teams/teamMember/${id}/inactive`, {}, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    console.log(data);
    dispatch(getOneTeamDetails(team_id, sort, search));
  } catch (error) {
    dispatch({
      type: CUSTOMER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
import React, { useRef, useState } from 'react';
import classes from "./customer.module.css";
import { Modal, Typography, Button, Box, InputAdornment } from "@mui/material";
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import sendInvoiceImage from "../../../Assets/images/sendInvoiceSuccessImage.png";
import { IsEmailCheck, Numeric } from '../../../Utilities/commonfunctions';
import { MdClose } from 'react-icons/md';
import { useEffect } from 'react';

const InvoiceSendModel = ({ item, modalClose, value }) => {
    const [modaltoggle, setModalToggle] = useState(true);
    const [success, setSuccess] = useState(false);
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const rootRef = useRef(null);

    const closeModal = () => {
        modalClose();
        setModalToggle(false);
    };

    useEffect(() => {
        if (value) {
            console.log("It cames to modal");
            setSuccess(true);
        }
    }, [value])

    const handleSubmit = () => {
        setSuccess(true);
    }

    return (
        <div>
            <Modal
                open={modaltoggle}
                onClose={() => closeModal()}
                aria-labelledby="server-modal-title"
                data-testid="register_modal"
                aria-describedby="server-modal-description"
                sx={{
                    display: "flex",
                    height: 480,
                    marginTop: 10,
                    justifyContent: "center",
                }}
                container={() => rootRef.current}
            >
                <Box
                    sx={{
                        position: "relative",
                        bgcolor: "background.paper",
                        borderRadius: 1,
                        boxShadow: (theme) => theme.shadows[5],
                    }}
                    style={{
                        width: "460px",
                        height: "max-content",
                        top: "20%",
                    }}
                    className={classes.loginModalWidth}
                >
                    <div className={classes.sendInvoiceModalClose}>
                        <Button
                            variant="text"
                            sx={{ minWidth: "unset" }}
                            className={"closebtn"}
                            onClick={() => closeModal()}
                        >
                            <MdClose />
                        </Button>
                    </div>
                    {!success ? (
                        <div>
                            <div className={classes.ModalHeadingContainer}>
                                <Typography className={classes.modalHeading}>Send Invoice</Typography>
                            </div>
                            <div>
                                <ValidatorForm
                                    useref="form"
                                    onSubmit={() => handleSubmit()}
                                    className={`mb-3 mt-3 ${classes.ValidatorForm}`}
                                >
                                    <div className='mt-4'>
                                        <Typography>Email ID</Typography>
                                        <TextValidator
                                            sx={{
                                                "& legend": { display: "none" },
                                                "& fieldset": { top: 0 },
                                            }}
                                            id="emailidInput"
                                            fullWidth={true}
                                            onKeyPress={(e) => IsEmailCheck(e)}
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            size="small"
                                            validators={["isEmail", "required"]}
                                            errorMessages={[
                                                "email is not valid",
                                                "Email is Required",
                                            ]}
                                        />
                                    </div>
                                    <div className='mb-5 mt-4'>
                                        <Typography>Mobile Number</Typography>
                                        <TextValidator
                                            sx={{
                                                "& legend": { display: "none" },
                                                "& fieldset": { top: 0 },
                                                width: "100%"
                                            }}
                                            inputProps={{
                                                maxLength: 10,
                                            }}
                                            id="phonenumberInput"
                                            value={mobile}
                                            onChange={(e) => setMobile(e.target.value)}
                                            onKeyPress={(e) => Numeric(e)}
                                            size="small"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        +91
                                                    </InputAdornment>
                                                ),
                                            }}
                                            validators={["required", "matchRegexp:[0-9]{10}"]}
                                            errorMessages={[
                                                "Mobile Number is required",
                                                "Please Enter the 10 digit mobile number",
                                            ]}
                                        />
                                    </div>
                                    <Button
                                        variant='contained'
                                        fullWidth
                                        type='submit'
                                        className={classes.submitBtn}
                                    >
                                        Send
                                    </Button>
                                </ValidatorForm>
                            </div>
                        </div>
                    ) : (
                        <div className={classes.successContaoner}>
                            <img src={sendInvoiceImage} alt="sendInvoiceImage" width={140} height={140} />
                            <Typography className={`mt-2 ${classes.modalHeading}`}>Congratulations!</Typography>
                            <Typography className={`mt-2 mb-4 ${classes.successPageSubText}`}>We have sent invoice to your mail & mobile number.</Typography>
                            <Button
                                variant='contained'
                                fullWidth
                                onClick={() => closeModal()}
                                className={classes.doneBtn}
                            >
                                Done
                            </Button>
                        </div>
                    )}
                </Box>
            </Modal>
        </div>
    )
}

export default InvoiceSendModel
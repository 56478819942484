import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./Redux/store";
import Router from "./router";
import "@fontsource/noto-sans";
import "bootstrap/dist/css/bootstrap.css";
import "./Assets/css/nonModule.css";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <RouterProvider router={Router} />
  </Provider>
);
